import MenfisHome from "./menfis-home";
import BackToTop from "./backToTop";

export default function Home() {
  return (
    <>
      <main className="">
        <MenfisHome />
        <BackToTop />
      </main>
    </>
  );
}
